import * as Types from '../../../../../_generated-types/index';

import { gql } from '@apollo/client';
import { ContainerSiteTableRowFragmentDoc } from '../../_generated/index';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const SearchContainerSitesDocument = gql`
  query SearchContainerSites(
    $search: String
    $filter: [ContainerSiteFilterInput!]
    $page: PageInput
    $group: ContainerSiteGroupInput
    $sort: ContainerSiteSortInput
    $unit: WeightUnit!
    $display: WeightDisplay!
  ) {
    containerSites: searchContainerSites(
      search: $search
      filter: $filter
      page: $page
      group: $group
      sort: $sort
    ) {
      ...ContainerSiteTableRow
    }
    count: searchContainerSitesCount(search: $search, filter: $filter)
  }
  ${ContainerSiteTableRowFragmentDoc}
`;

/**
 * __useSearchContainerSitesQuery__
 *
 * To run a query within a React component, call `useSearchContainerSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchContainerSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchContainerSitesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      group: // value for 'group'
 *      sort: // value for 'sort'
 *      unit: // value for 'unit'
 *      display: // value for 'display'
 *   },
 * });
 */
export function useSearchContainerSitesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchContainerSitesQuery,
    SearchContainerSitesQueryVariables
  > &
    (
      | { variables: SearchContainerSitesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchContainerSitesQuery,
    SearchContainerSitesQueryVariables
  >(SearchContainerSitesDocument, options);
}
export function useSearchContainerSitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchContainerSitesQuery,
    SearchContainerSitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchContainerSitesQuery,
    SearchContainerSitesQueryVariables
  >(SearchContainerSitesDocument, options);
}
export function useSearchContainerSitesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SearchContainerSitesQuery,
        SearchContainerSitesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SearchContainerSitesQuery,
    SearchContainerSitesQueryVariables
  >(SearchContainerSitesDocument, options);
}
export type SearchContainerSitesQueryHookResult = ReturnType<
  typeof useSearchContainerSitesQuery
>;
export type SearchContainerSitesLazyQueryHookResult = ReturnType<
  typeof useSearchContainerSitesLazyQuery
>;
export type SearchContainerSitesSuspenseQueryHookResult = ReturnType<
  typeof useSearchContainerSitesSuspenseQuery
>;
export type SearchContainerSitesQueryResult = Apollo.QueryResult<
  SearchContainerSitesQuery,
  SearchContainerSitesQueryVariables
>;
export type SearchContainerSitesQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<
    Array<Types.ContainerSiteFilterInput> | Types.ContainerSiteFilterInput
  >;
  page?: Types.InputMaybe<Types.PageInput>;
  group?: Types.InputMaybe<Types.ContainerSiteGroupInput>;
  sort?: Types.InputMaybe<Types.ContainerSiteSortInput>;
  unit: Types.WeightUnit;
  display: Types.WeightDisplay;
}>;

export type SearchContainerSitesQuery = {
  __typename?: 'Query';
  count: number;
  containerSites: Array<{
    __typename?: 'ContainerSite';
    id: string;
    name: string;
    emptyContainerWeight?: number | null;
    size?: number | null;
    commodityType?: string | null;
    maxGrossWeight?: number | null;
    maxNetWeight?: number | null;
    maxDisplayWeight?: number | null;
    currentGrossWeight?: number | null;
    currentNetWeight?: number | null;
    currentDisplayWeight?: number | null;
    currentFullness?: number | null;
    serviceLocation: {
      __typename?: 'ServiceLocation';
      id: string;
      name: string;
      account: { __typename?: 'Account'; id: string; name: string };
    };
    binBar?: {
      __typename?: 'BinBar';
      id: string;
      name: string;
      batteryLevel?: number | null;
      firmwareConfig: {
        __typename?: 'FirmwareConfig';
        maxOfflineSeconds: number;
      };
    } | null;
    lastLocation?: {
      __typename?: 'CellularLocation';
      latitude: number;
      longitude: number;
      radius: number;
    } | null;
    lastWeight?: {
      __typename?: 'WeightPost';
      id: string;
      timestamp: any;
    } | null;
    assignedDriver?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
    } | null;
    containerCycles: Array<{
      __typename?: 'ContainerCycle';
      id: string;
      removal?: any | null;
      removalPhotos: Array<{
        __typename?: 'CloudStorageFile';
        id: string;
        status: Types.UploadStatus;
      }>;
    }>;
    installPhotos: Array<{
      __typename?: 'CloudStorageFile';
      id: string;
      status: Types.UploadStatus;
    }>;
  }>;
};
